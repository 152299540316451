<template>
  <Layout :tituloPagina="'Network | Routers | '+(modo == 'nuevo' ? 'Nuevo' : 'Edición')">
    <div class="row">
      <div class="col-md-7">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title text-truncate w-100" v-if="modo == 'nuevo'">
              Nuevo router
              <small>
                Aquí puedes registrar un nuevo router
              </small>
            </h4>
            <h4 class="card-title text-truncate w-100" v-if="modo == 'edicion'">
              Edición de router
              <small>
                Aquí puedes editar los datos del router registrado
              </small>
            </h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                      v-if="!bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row" v-show="modo!='nuevo'">
              <div class="col-md-6">
                <label>Id</label>
                <input
                  class="form-control"
                  ref="id"
                  v-model="router.id"
                  readonly
                  placeholder="En proceso de asignación ..."
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>Nombre</label>
                <input
                  class="form-control"
                  ref="nombre"
                  placeholder="Nombre del router"
                  v-model="router.nombre"
                />
              </div>
              <div class="col-md-6" v-show="modo!='nuevo'">
                <label>Dirección IP (VPN)</label>
                <div class="input-group">
                  <input
                    class="form-control"
                    ref="ip"
                    v-model="router.ip"
                    readonly
                    placeholder="En proceso de asignación ..."
                  />
                  <button class="btn btn-primary" @click="cambiarIpVpn()">
                    <i class="mdi mdi-refresh"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label>Descripción</label>
                <textarea
                  class="form-control"
                  ref="descripcion"
                  v-model="router.descripcion"
                  placeholder="Descripción del router"
                  rows="4"
                ></textarea>
              </div>
            </div>
            <!-- <br />
            <div class="row">
              <div class="col-md-3">
                <label>Tipo de gestión</label>
                <select v-model="router.tipo_gestion" class="form-select">
                  <option :value="0">Sin gestión</option>
                  <option :value="1">Solo VPN</option>
                  <option :value="2">Automática</option>
                </select>
              </div>
            </div> -->
            <br>
            <div class="row">
              <div class="col-lg-3 col-md-3 col-6">
                <label class="font-size-13">Sitio</label>
                <div class="input-group">
                  <router-link
                    :to="{ name: 'nuevoSitio' }"
                    title="Nuevo sitio"
                    class="btn btn-success"
                  >
                    <i class="mdi mdi-plus-thick"></i>
                  </router-link>
                  <select
                    v-model="router.id_sitio"
                    class="form-select"
                    ref="sitio"
                  >
                    <option :value="null">Seleccionar</option>
                    <option
                      v-for="sitio in sitios"
                      :value="sitio.id"
                      :key="sitio.id"
                    >
                      {{ sitio.nombre }}
                    </option>
                  </select>
                </div>
              </div>
              
              <div class="col-lg-3 col-md-3 col-6">
                <label class="font-size-13 text-truncate w-100">Registro en log Mikrotik</label>
                <div class="form-check form-switch form-switch-lg mb-3">
                  <input
                    :checked="router.registro_en_log"
                    @change="router.registro_en_log = !router.registro_en_log"
                    class="form-check-input"
                    type="checkbox"
                    id="registroLog"
                  />
                  <label class="form-check-label" for="registroLog"></label>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-6">
                <label class="font-size-13 text-truncate w-100">Notificación por router activo</label>
                <div class="form-check form-switch form-switch-lg mb-3">
                  <input
                    :checked="router.nra"
                    @change="router.nra = !router.nra"
                    class="form-check-input"
                    type="checkbox"
                    id="nra"
                  />
                  <label class="form-check-label" for="nra"></label>
                </div>
              </div>

              <div class="col-lg-3 col-md-3 col-6">
                <label class="font-size-13 text-truncate w-100">Notificación por router caido</label>
                <div class="form-check form-switch form-switch-lg mb-3">
                  <input
                    :checked="router.nrc"
                    @change="router.nrc = !router.nrc"
                    class="form-check-input"
                    type="checkbox"
                    id="nrc"
                  />
                  <label class="form-check-label" for="nrc"></label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-3 col-md-3 col-6">
                <label class="font-size-13 text-truncate w-100" title="Regla de servicio suspendido para usuarios no registrados">
                  Regla de servicio suspendido para usuarios no registrados
                </label>
                <div class="form-check form-switch form-switch-lg mb-3">
                  <input
                    :checked="router.rssunr"
                    @change="
                      router.rssunr = !router.rssunr,
                      router.rssunr_sinc = false
                    "
                    class="form-check-input"
                    type="checkbox"
                    id="reglaServicioSuspendido"
                    :title="
                      !router.rssunr ?
                      'Activar regla de servicio suspendido para usuarios no registrados' :
                      'Desactivar regla de servicio suspendido para usuarios no registrados'
                    "
                  />
                  <label
                    class="form-check-label"
                    for="reglaServicioSuspendido"
                  ></label>
                </div>
              </div>
            </div>

            <br />

            <div class="row" v-if="modo == 'edicion' && router.id != null">
              <div class="col-md-6">
                <label class="text-truncate w-100">Proveedores de internet</label>
                <br />
                <router-link
                  class="btn btn-sm"
                  :class="{
                    'btn-outline-success': router.cantidad_isps > 0,
                    'btn-outline-warning':
                      router.cantidad_isps == 0 ||
                      router.cantidad_isps == null
                  }"
                  :to="{
                    name: 'listadoProveedoresInternetRouter',
                    params: { id: router.id }
                  }"
                >
                  <i class="mdi mdi-format-list-bulleted-square font-size-14"></i>
                  Editar proveedores
                  <sup v-show="router.cantidad_isps > 0">
                    {{ '(' + router.cantidad_isps + ' ISPs)' }}
                  </sup>
                </router-link>
              </div>

              <div class="col-md-6">
                <label class="text-truncate w-100">Redes de area local (LAN)</label> <br />
                <router-link
                  class="btn btn-sm"
                  :class="{
                    'btn-outline-success': router.cantidad_lans > 0,
                    'btn-outline-warning':
                      router.cantidad_lans == 0 ||
                      router.cantidad_lans == null
                  }"
                  :to="{
                    name: 'edicionLansRouter',
                    params: { id: router.id }
                  }"
                >
                  <i class="mdi mdi-format-list-bulleted-square font-size-14"></i>
                  Mostrar lista
                  <sup v-show="router.cantidad_lans > 0">
                    {{ '(' + router.cantidad_lans + ' LANs)' }}
                  </sup>
                </router-link>
              </div>
            </div>

            <br /><br />
            <div class="row" v-if="modo == 'edicion'">
              <div class="col-md-6">
                <div class="row align-items-center">
                  <label class="col-7 text-truncate">Otras funciones del sistema</label>
                  <div class="col-5">
                    <button
                      class="btn btn-light btn-xs"
                      @click="
                        opciones_sincronizacion_visibles = !opciones_sincronizacion_visibles
                      "
                    >
                      <i
                        class="fa"
                        :class="{
                          'fa-angle-down': !opciones_sincronizacion_visibles,
                          'fa-angle-up': opciones_sincronizacion_visibles
                        }"
                      ></i>
                    </button>
                  </div>
                </div>
                
                <br />

                <div v-show="opciones_sincronizacion_visibles">
                  <button
                    class="btn btn-light btn-sm"
                    title="Fuerza la resincronización de las interfaces del router en sistema"
                    @click="resincronizarInterfaces()"
                    :disabled="router.ifs_sinc == false"
                  >
                    <i class="mdi mdi-refresh"></i>
                    {{ router.ifs_sinc ? 'Resincronizar' : 'Sincronizando' }}
                    interfaces
                  </button>
                  <br>

                  <button
                    class="btn btn-light btn-sm"
                    title="Fuerza la resincronización de la configuración base del router"
                    @click="resincronizarConfiguracionBase()"
                    :disabled="router.bs_sinc == false"
                  >
                    <i class="mdi mdi-refresh"></i>
                    {{ router.bs_sinc ? 'Resincronizar ' : 'Sincronizando ' }}
                    configuración base
                  </button>
                  <br>

                  <button
                    class="btn btn-light btn-sm"
                    title="Fuerza la resincronización de los perfiles de velocidad"
                    @click="resincronizarPerfilesVelocidad()"
                    :disabled="router.pv_sinc == false"
                  >
                    <i class="mdi mdi-refresh"></i>
                    {{ router.pv_sinc ? 'Resincronizar' : 'Sincronizando' }}
                    los perfiles de velocidad
                  </button>
                  <br>

                  <button
                    class="btn btn-light btn-sm"
                    title="Fuerza la resincronización de las configuraciones de los CPEs en el router"
                    @click="resincronizarConfiguracionCpes()"
                    :disabled="router.cantidad_cpes.por_sincronizar > 0"
                  >
                    <i class="mdi mdi-refresh"></i>
                    {{
                      router.cantidad_cpes.por_sincronizar > 0
                        ? 'Sincronizando'
                        : 'Resincronizar'
                    }}
                    configuración de todos los CPEs
                  </button>
                  <br>

                  <button
                    class="btn btn-light btn-sm"
                    title="Resincroniza los scripts usados por ArgusBlack en el router"
                    @click="resincronizarScripts()"
                    :disabled="router.sc_sinc == false"
                  >
                    <i class="mdi mdi-refresh"></i>
                    {{ router.sc_sinc ? 'Resincronizar' : 'Sincronizando' }}
                    los scripts
                  </button>
                  <br>

                  <button
                    class="btn btn-light btn-sm"
                    title="Resincroniza el Failover para ISPs en el router"
                    @click="resincronizarIspsFailover()"
                    :disabled="router.f1_sinc == false"
                  >
                    <i class="mdi mdi-refresh"></i>
                    {{ router.f1_sinc ? 'Resincronizar' : 'Sincronizando' }}
                    el Failover para ISPs 
                  </button>
                  <br>


                  <button
                    class="btn btn-danger btn-sm"
                    title="Fuerza la resincronización del router, limpiando y recargando exclusivamente toda la configuración de ArgusBlack"
                    @click="resincronizarTodo()"
                    :disabled="
                      router.ifs_sinc == false &&
                        router.bs_sinc == false &&
                        router.pv_sinc == false && 
                        router.sc_sinc == false
                    "
                  >
                    <i class="mdi mdi-refresh"></i>
                    {{
                      router.ifs_sinc &&
                      router.bs_sinc &&
                      router.pv_sinc &&
                      router.sc_sinc
                        ? 'Resincronizar toda la configuración'
                        : 'Sincronizando toda la configuración'
                    }}
                  </button>


                  <br><br>
                  <button
                    class="btn btn-light btn-sm"
                    title="Vuelve a iniciar la configuración de la VPN para el router"
                    @click="reiniciarVpn()"
                    :disabled="router.estado_vpn == 0"
                  >
                    <i class="mdi mdi-refresh"></i>
                    {{ router.estado_vpn > 0 ? 'Reiniciar' : 'Reiniciando' }}
                    VPN
                  </button>
                  <br>
                  
                  <button
                    class="btn btn-light btn-sm"
                    title="Solicita la renovación de certificado para el router. Solo ejecutar si el enlace a la VPN no se establece."
                    @click="renovarCertificadoRouter()"
                    :disabled="router.bloquear_boton_resincronizar_certificado"
                  >
                    <i class="mdi mdi-refresh"></i>
                    {{
                      router.bloquear_boton_resincronizar_certificado
                        ? 'Renovando'
                        : 'Renovar'
                    }}
                    certificado
                  </button>
                  <br/>


                  <button class="btn btn-light btn-sm"
                    title="Limpia la configuración de ArgusBlack en el router y lo desenlasa de la VPN"
                    @click="limpiarYDesenlazarRouter()"
                    :disabled="router.bloquear_boton_limpiar_y_desenlazar">
                    <i class="mdi mdi-alert-circle-outline"></i>
                    Limpiar configuración y desenlazar router de la VPN
                  </button>
                  <br/>
                </div>
              </div>

              <div class="col-md-6">
                <label>Conexión a la API</label> &nbsp;
                <span
                  class="badge bg-primary bg-gradient font-size-13"
                  v-if="router.intentos_conexion_fallidos_api == 0"
                >
                  <i class="mdi mdi-check-bold"></i>
                  Correcta
                </span>
                <br />

                <div v-if="router.intentos_conexion_fallidos_api > 0">
                  Intentos fallidos:
                  {{ router.intentos_conexion_fallidos_api }} <br />
                  Próximo intento: {{ router.proximo_intento_conexion_api }}
                  <br />
                  <br />
                  <button
                    class="btn btn-outline-warning btn-sm"
                    @click="reintentarConexionApi()"
                  >
                    <i class="mdi mdi-flash"></i>
                    Reintentar conexión ahora
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-5">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title text-truncate w-100">
              Administración de router
            </h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                      v-if="!bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row" v-show="modo != 'edicion' || tienePermiso('cmp.network.credencialAdministracionRouterVisible','componente')">
              <div class="col-md-6">
                <label>Usuario</label>
                <div class="input-group">
                  <input
                    class="form-control"
                    ref="usuario"
                    placeholder="Usuario"
                    v-model="router.usuario"
                    :readonly="router.usuario_bloqueado"
                  />
                  <button
                    class="btn btn-primary"
                    @click="
                      router.usuario_bloqueado = !router.usuario_bloqueado
                    "
                  >
                    <i
                      class="fa"
                      :class="{
                        'fa-lock': router.usuario_bloqueado,
                        'fa-unlock': !router.usuario_bloqueado
                      }"
                    ></i>
                  </button>
                </div>
              </div>
              <div class="col-md-6">
                <label>Clave</label>
                <div class="input-group">
                  <input
                    class="form-control"
                    ref="clave"
                    :placeholder="mostrar_clave ? 'Password' : '******'"
                    :type="mostrar_clave ? 'text' : 'password'"
                    v-model="router.clave"
                    :readonly="router.clave_bloqueada"
                  />
                  <button
                    class="btn btn-primary"
                    @click="mostrar_clave = !mostrar_clave"
                  >
                    <i
                      class="fa"
                      :class="{
                        'fa-eye': mostrar_clave,
                        'fa-eye-slash': !mostrar_clave
                      }"
                    ></i>
                  </button>
                  <button
                    class="btn btn-primary"
                    @click="router.clave_bloqueada = !router.clave_bloqueada"
                  >
                    <i
                      class="fa"
                      :class="{
                        'fa-lock': router.clave_bloqueada,
                        'fa-unlock': !router.clave_bloqueada
                      }"
                    ></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-sm-6 col-xs-6">
                <label>Puerto de API</label>
                <div class="input-group">
                  <input
                    type="number"
                    min="0"
                    placeholder="8728"
                    ref="puerto_api"
                    v-model="router.puerto_api"
                    class="form-control text-right"
                    :readonly="router.puerto_api_bloqueado"
                  />
                  <button
                    class="btn btn-primary"
                    @click="router.puerto_api_bloqueado = !router.puerto_api_bloqueado"
                  >
                    <i
                      class="fa"
                      :class="{
                        'fa-lock': router.puerto_api_bloqueado,
                        'fa-unlock': !router.puerto_api_bloqueado
                      }"
                    ></i>
                  </button>
                </div>
              </div>
              <div class="col-lg-4 col-sm-6 col-xs-6">
                <label>Puerto web proxy</label>
                <div class="input-group">
                  <input
                    type="number"
                    min="0"
                    placeholder="8080"
                    ref="puerto_web_proxy"
                    v-model="router.puerto_web_proxy"
                    class="form-control text-right"
                    :readonly="router.puerto_web_proxy_bloqueado"
                  />
                  <button
                    class="btn btn-primary"
                    @click="router.puerto_web_proxy_bloqueado = !router.puerto_web_proxy_bloqueado"
                  >
                    <i
                      class="fa"
                      :class="{
                        'fa-lock': router.puerto_web_proxy_bloqueado,
                        'fa-unlock': !router.puerto_web_proxy_bloqueado
                      }"
                    ></i>
                  </button>
                </div>
              </div>
              <div class="col-lg-4 col-sm-6 col-xs-6">
                <label>Puerto Winbox</label>
                <div class="input-group">
                  <input
                    ref="puerto_winbox"
                    v-model="router.puerto_winbox"
                    placeholder="- - - -"
                    class="form-control text-right"
                    readonly
                  />
                  <button class="btn btn-primary" @click="resincronizarPuertoWinbox()">
                    <i class="mdi mdi-refresh"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title text-truncate w-100">
              DNS sugeridos para clientes
            </h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                      v-if="!bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <label>DNS 1</label>
                <input
                  ref="dns_1"
                  type="text"
                  class="form-control"
                  placeholder="8.8.4.4"
                  v-model="router.dns1_sugerido"
                  title="DNS 1 que se entregará a los clientes PPPoE. En caso de no ser registrado, se usarán los DNS que use el router administrador"
                />
              </div>
              <div class="col-md-6">
                <label>DNS 2</label>
                <input
                  ref="dns_2"
                  type="text"
                  class="form-control"
                  placeholder="8.8.8.8"
                  v-model="router.dns2_sugerido"
                  title="DNS 2 que se entregará a los clientes PPPoE. En caso de no ser registrado, se usarán los DNS que use el router administrador"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title text-truncate w-100">
              Habilitar o deshabilitar el historial del tráfico general
            </h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                      v-if="!bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-lg-4 col-md-4">
                <label class="font-size-13 text-truncate w-100">Todos los proveedores ISP</label>
                <div class="form-check form-switch form-switch-lg mb-3">
                  <input
                    :checked="router.ht_isps"
                    @change="router.ht_isps = !router.ht_isps"
                    class="form-check-input"
                    type="checkbox"
                    id="historialIsps"
                  />
                  <label class="form-check-label" for="historialIsps"></label>
                </div>
              </div>
              <div class="col-lg-4 col-md-4">
                <label class="font-size-13 text-truncate w-100">Todas las redes LAN</label>
                <div class="form-check form-switch form-switch-lg mb-3">
                  <input
                    :checked="router.ht_lans"
                    @change="router.ht_lans = !router.ht_lans"
                    class="form-check-input"
                    type="checkbox"
                    id="historialLans"
                  />
                  <label class="form-check-label" for="historialLans"></label>
                </div>
              </div>
              <div class="col-lg-4 col-md-4">
                <label class="font-size-13 text-truncate w-100">Todos los CPEs</label>
                <div class="form-check form-switch form-switch-lg mb-3">
                  <input
                    :checked="router.ht_cpes"
                    @change="router.ht_cpes = !router.ht_cpes"
                    class="form-check-input"
                    type="checkbox"
                    id="historialCpes"
                  />
                  <label class="form-check-label" for="historialCpes"></label>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <div class="card" v-if="modo == 'edicion'">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title text-truncate w-100">
              autoconfig.rsc
            </h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                      v-if="!bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="d-grid gap-2">
              <button
                class="btn btn-light btn-block"
                ref="autoconfig"
                @click="descargar()"
              >
                <i class="mdi mdi-cloud-download font-size-15"></i>
                Descargar
              </button>
            </div>
            
            <p
              v-if="router.id != null && router.estado_configuracion == 0"
              class="text-warning"
            >
              Es necesario descargar e importar el archivo
              <strong>autoconfig.rsc</strong>, para realizar la configuración
              de tu router de forma automática.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="text-right">
      <div class="btn-group">
        <button class="btn btn-secondary" @click="atras()">
          <i class="mdi mdi-chevron-left"></i>
          Atrás
        </button>

        <button
          class="btn btn-success"
          @click="modo == 'nuevo' ? guardar() : actualizar()"
          :disabled="bandera_spinner"
        >
          <i 
            class="mdi"
            :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
          ></i>
          {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
        </button>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue"
import API from '@/API.js'
import RouterSrv from '@/services/RouterSrv.js'
import SitioSrv from '@/services/SitioSrv.js'

import { todoGetters } from "@/state/helpers"
import Swal from 'sweetalert2'

export default {
  name: 'EdicionRouter',
  components: { Layout },
  data() {
    return {
      modo: 'nuevo',
      mostrar_clave: false,
      router: {
        id: null,
        nombre: '',
        ip: '',
        descripcion: '',
        usuario: '',
        clave: '',
        puerto_api: 8728,
        puerto_web_proxy: 8080,
        puerto_winbox: '',
        usuario_bloqueado: true,
        clave_bloqueada: true,
        puerto_api_bloqueado: true,
        puerto_web_proxy_bloqueado:true,
        latitud: 0,
        longitud: 0,
        id_sitio: null,
        ifs_sinc: 0,
        bs_sinc: 0,
        pv_sinc: 0,
        sc_sinc: 0,
        f1_sinc: 0,
        intentos_conexion_fallidos_api: 0,
        proximo_intento_conexion_api: null,
        dns1_sugerido: null,
        dns2_sugerido: null,
        cantidad_cpes: {
          total: 0,
          sincronizados: 0,
          por_sincronizar: 0
        },
        bloquear_boton_resincronizar_certificado: false,
        bloquear_boton_limpiar_y_desenlazar: false,
        estado_vpn: 0,
        registro_en_log: true,
        nra: true,
        nrc: true,
        // tipo_gestion: 0

        ht_isps: 0,
        ht_lans: 0,
        ht_cpes: 0,
        ht_isps_sinc: 0,
        ht_lans_sinc: 0,
        ht_cpes_sinc: 0,

        rssunr: 1,
        rssunr_sinc: 0
        
      },
      sitios: [],
      interval: null,
      opciones_sincronizacion_visibles: false,
      bandera_spinner: false
    }
  },

  watch: {
    'router.ht_isps': function(newVar, oldVar) {
      this.router.ht_isps_sinc = false
    },
    'router.ht_lans': function(newVar, oldVar) {
      this.router.ht_lans_sinc = false
    },
    'router.ht_cpes': function(newVar, oldVar) {
      this.router.ht_cpes_sinc = false
    }
  },

  beforeUnmount() {
    if (this.interval != null) {
      clearInterval(this.interval)
    }
  },

  created() {
    var self = this

    // Detección de modo
    if (this.$route.path.indexOf('routers/nuevo') >= 0) this.modo = 'nuevo'
    else this.modo = 'edicion'

    // Cargas iniciales
    switch (this.modo) {
      case 'nuevo':
        Object.assign(self.router, {
          usuario_bloqueado: false,
          clave_bloqueada: false,
          puerto_api_bloqueado: false,
          puerto_web_proxy_bloqueado: false
        })
        break
      case 'edicion':
        self.cargarRouter()
        break
    }

    this.cargarSitios()

    this.iniciarActualizadorIfsBsSincRouter()
  },

  computed: {
    ...todoGetters
  },

  methods: {
    actualizar() {
      var self = this

      var router = Object.assign({}, self.router)

      self.bandera_spinner = true

      if (router.nombre == null || router.nombre == '') {
        iu.msg.warning('Es necesario un nombre para registrar el router')
        self.$refs.nombre.select()
        self.bandera_spinner = false
        return
      }

      if (router.id_sitio == null) {
        iu.msg.warning('Es necesario seleccionar un sitio')
        self.$refs.sitio.focus()
        self.bandera_spinner = false
        return
      }

      if (router.puerto_api == null || router.puerto_api == '') {
        iu.msg.warning('Es necesario un valor para el puerto api del router')
        self.$refs.puerto_api.focus()
        self.bandera_spinner = false
        return
      }

      if (router.puerto_web_proxy == null || router.puerto_web_proxy == '') {
        iu.msg.warning('Es necesario un valor para el puerto web proxy del router')
        self.$refs.puerto_web_proxy.focus()
        self.bandera_spinner = false
        return
      }

      // Carga de la latitud y longitud del router en función del sitio donde estén
      // instalados
      var sitio = self.obtenerSitio(router.id_sitio)
      router.latitud_gps = sitio.latitud
      router.longitud_gps = sitio.longitud

      RouterSrv.actualizar(router).then(response => {
        iu.msg.success('Se actualizó correctamente')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    atras() {
      this.$router.go(-1)
    },

    cargarRouter() {
      var self = this

      RouterSrv.routerJSON(self.$route.params.id).then(response => {
        let router = response.data
        Object.assign(self.router, router)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el router'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarSitios() {
      var self = this

      SitioSrv.sitiosJSON().then(response => {
        self.sitios = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los sitios'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cambiarIpVpn() {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Cambiar la dirección IP (VPN)",
        html: '¿Está seguro de que desea cambiar la dirección IP (<strong>'+this.router.ip+'</strong>) del router, por una nueva en el sistema?',
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          RouterSrv.cambiarIP(self.router.id).then(response => {
            swal.fire("Cambiar la dirección IP (VPN)", "Se está cambiando la dirección IP del router por una nueva en el sistema.", "success")
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = ''
            }
            mensaje != '' ? iu.msg.error(mensaje) : ''
            console.log(error)
          }).finally(() => {
            self.cargarRouter()
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    cerrar() {
      this.atras()
    },

    descargar() {
      let tk = localStorage.getItem('argusblack.token')
      window.open(`${API}/routers/${this.router.id}/autocfg?_tk=${tk}`, '_blank')
    },

    guardar() {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success"
        },
        buttonsStyling: false
      })

      var router = Object.assign({}, self.router)
      self.bandera_spinner = true

      if (router.nombre == null || router.nombre == '') {
        iu.msg.warning('Es necesario un nombre para registrar el router')
        self.$refs.nombre.select()
        self.bandera_spinner = false
        return
      }

      if (router.id_sitio == null) {
        iu.msg.warning('Es necesario seleccionar un sitio')
        self.$refs.sitio.focus()
        self.bandera_spinner = false
        return
      }

      if (router.puerto_api == null || router.puerto_api == '') {
        iu.msg.warning('Es necesario un valor para el puerto api del router')
        self.$refs.puerto_api.focus()
        self.bandera_spinner = false
        return
      }

      if (router.puerto_web_proxy == null || router.puerto_web_proxy == '') {
        iu.msg.warning('Es necesario un valor para el puerto web proxy del router')
        self.$refs.puerto_web_proxy.focus()
        self.bandera_spinner = false
        return
      }

      // Carga de la latitud y longitud del router en función del sitio donde estén
      // instalados
      var sitio = self.obtenerSitio(router.id_sitio)
      router.latitud_gps = sitio.latitud
      router.longitud_gps = sitio.longitud

      RouterSrv.guardar(router).then(response => {
        let id = response.data
        iu.msg.success('Se guardó correctamente el router')
        self.$router.replace({ name: 'edicionRouter', params: { id: id } })
        self.modo = 'edicion'
        self.router.id = id

        if (self.$route.query.hotspot) sessionStorage.setItem('argusblack.hotspot.id_router', id)

        swal.fire({
          title: "Importante",
          html: `Para poder continuar, es necesario que descargues el "<strong>autoconfig.rsc</strong>",
                 lo importes a tu router Mikrotik con el comando: <strong>import autoconfig.rsc</strong> 
                 y una vez conectado regreses a esta interfaz, para poder continuar.`,
          icon: "info",
          confirmButtonText: "AutoConfig!"
        }).then(result => {
          if (result.value) {
            var btn = self.$refs.autoconfig
            setTimeout(function() {
              btn.style.transition = '0.8s'
              btn.style.opacity = 0
              btn.classList.remove('btn-light')
              btn.classList.add('btn-success')
              btn.style.transition = '0.8s'
              btn.style.opacity = 1
            }, 500)
            setTimeout(function() {
              btn.classList.remove('btn-success')
              btn.classList.add('btn-light')
            }, 2000)
          }
        })
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar el router'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    iniciarActualizadorIfsBsSincRouter() {
      var self = this

      if (self.router.bs_sinc == true) return

      self.interval = setInterval(function() {
        if (self.router.id == null) return

        RouterSrv.sincJSON(self.router.id).then(response => {
          let data = response.data
          self.router.ifs_sinc = data.ifs_sinc
          self.router.bs_sinc = data.bs_sinc

          // Detiene el interval cuando el estado de la configuración ha llegado a 2,
          // en este punto, el sistema ya tiene la lista de interfaces con que cuenta
          // el router
          if (self.router.bs_sinc == true) {
            clearInterval(self.interval)
          }
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudo sincronizar'
          }
          iu.msg.error(mensaje)
          console.log(error)
        })
      }, 5000)
    },

    limpiarYDesenlazarRouter() {
      let self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Limpieza y desenlazamiento del router",
        html: '¿Estás seguro que deseas limpiar y desenlazar el router?, '+
              'cuando lo hagas vas a perder conexión con el router.',
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          iu.msg.info('Espere un momento...')
          self.bloquear_boton_limpiar_y_desenlazar = true
          RouterSrv.limpiarYDesenlazarRouter(self.router.id).then(response => {
            swal.fire("Limpieza del router!", "Se iniciará el proceso de limpieza y desenlazado del router", "success")
            self.bloquear_boton_limpiar_y_desenlazar = false
          }).catch(error => {
            self.bloquear_boton_limpiar_y_desenlazar = false
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    obtenerIpRouter() {
      var self = this

      RouterSrv.ip(self.router.id).then(response => {
        self.router.ip = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar la IP'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    obtenerSitio(idSitio) {
      var self = this

      var filtrados = self.sitios.filter(function(st) {
        return st.id == idSitio
      })

      if (filtrados.length == 0) return null
      return filtrados[0]
    },

    reintentarConexionApi() {
      var self = this
      iu.msg.info('Reintentando conexión con la API del router')
      
      RouterSrv.reintentarConexionApi(self.router.id).then(response => {
        self.router.intentos_conexion_fallidos_api = 0
        self.router.proximo_intento_conexion_api = null
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },

    reiniciarVpn() {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Reiniciar VPN",
        html: '¿Está seguro que desea reiniciar la VPN del router?, ' +
              'Si ya tienes conectado tu router, se deconectará y reconectará brevemente.',
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          self.router.estado_vpn = 0
          RouterSrv.reiniciarVpn(self.router.id).then(response => {
            swal.fire("Reiniciar VPN!", "Se esta reiniciando la VPN", "success")
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = ''
            }
            mensaje != '' ? iu.msg.error(mensaje) : ''
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    renovarCertificadoRouter() {
      let self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Renovar certifiado",
        html: '¿Está seguro que desea renovar el certificado del router?',
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          self.router.bloquear_boton_resincronizar_certificado = true
          RouterSrv.renovarCertificadoRouter(self.router.id).then(response => {
            swal.fire("Renovar certificado!", "Se esta renovando el certificado en el router", "success")
          }).catch(error => {
            iu.msg.error('No se pudo completar la renovación del certificado del router')
          })
          setTimeout(function(){
            self.router.bloquear_boton_resincronizar_certificado = false
          }, 30000)
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    resincronizarConfiguracionBase() {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Resincronización de configuración base",
        html: '¿Está seguro que desea resincronizar la configuración base del router?<br><br>' +
              ' Si el router tiene reglas faltantes en mangle, filter, address-list, address, dhcp-client, route ' +
              ' serán agregadas.',
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          self.router.bs_sinc = false
          RouterSrv.resincronizar(self.router.id, 'configuracion-base').then(response => {
            swal.fire("Configuración base!", "Se está resincronizando la configuración base en el router", "success")
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = ''
            }
            mensaje != '' ? iu.msg.error(mensaje) : ''
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    resincronizarConfiguracionCpes() {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Resincronización de configuración de todos los CPEs",
        html: '¿Está seguro que desea resincronizar la configuración de todos los CPEs en el router?<br><br>' +
              ' Si el router tiene reglas faltantes en mangle, address-list o simple queue ' +
              ' serán agregadas.',
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          self.router.cantidad_cpes.por_sincronizar = self.router.cantidad_cpes.total
          self.router.cantidad_cpes.sincronizados = 0
          RouterSrv.resincronizar(self.router.id, 'configuracion-cpes').then(response => {
            swal.fire("Resincronización CPEs!", "Se está resincronizando la configuración de todos los CPEs", "success")
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = ''
            }
            mensaje != '' ? iu.msg.error(mensaje) : ''
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    resincronizarIspsFailover() {
      let self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Resincronización del Failover para ISPs",
        html: '¿Está seguro que desea resincronizar el failover para ISPs del router?',
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          self.router.f1_sinc = false
          RouterSrv.resincronizar(self.router.id, 'isps-failover').then(response => {
            swal.fire("Resincronización del Failover!", "Se está resincronizando el Failover para los ISPs del router", "success")
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = ''
            }
            mensaje != '' ? iu.msg.error(mensaje) : ''
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    resincronizarInterfaces() {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Resincronización de interfaces",
        html: '¿Está seguro que desea resincronizar las interfaces del router en sistema?<br><br>' +
              ' si hubo algún cambio en las interfaces, serán actualizadas en sistema. ' +
              ' Las que no hayan sufrido cambio quedarán igual.',
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          self.router.ifs_sinc = false
          RouterSrv.resincronizar(self.router.id, 'interfaces').then(response => {
            swal.fire("Resincronización de interfaces!", "Se está resincronizando las interfaces del router", "success")
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = ''
            }
            mensaje != '' ? iu.msg.error(mensaje) : ''
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    resincronizarPerfilesVelocidad: function() {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Resincronización de perfiles de velocidad",
        html: '¿Está seguro que desea resincronizar los perfiles de velocidad del router?<br><br>' +
              ' Esto actualizará el mangle, queue-tree, queue type y simple queue de ser necesario. ',
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          self.router.pv_sinc = false
          RouterSrv.resincronizar(self.router.id, 'perfiles-velocidad').then(response => {
            swal.fire("Resincronización de perfiles!", "Se está resincronizando los perfiles de velocidad del router", "success")
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = ''
            }
            mensaje != '' ? iu.msg.error(mensaje) : ''
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    resincronizarPuertoWinbox() {
      let self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Resincronización de puerto Winbox",
        html: '¿Está seguro que desea resincronizar el puerto Winbox en sistema?',
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          RouterSrv.resincronizar(self.router.id, 'puerto-winbox').then(response => {
            swal.fire("Resincronización de puerto Winbox!", "Se está resincronizando el puerto de Winbox en el sistema", "success")
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = ''
            }
            mensaje != '' ? iu.msg.error(mensaje) : ''
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    resincronizarScripts() {
      let self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Resincronizar scripts",
        html: '¿Está seguro que desea resincronizar los scripts?',
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          self.router.sc_sinc = false
          RouterSrv.resincronizar(self.router.id, 'scripts').then(response => {
            swal.fire(
              "Resincronizar scripts",
              "La resincronización está comenzado, manten conectado tu router y no lo desconectes de la energía ni de la conexión a internet",
              "success"
            )
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = ''
            }
            mensaje != '' ? iu.msg.error(mensaje) : ''
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    resincronizarTodo: function() {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Resincronización de toda la configuración",
        html: '¿Está seguro que desea resincronizar el router?<br><br>'+
              'Esto borrará exclusivamente la configuración cargada por ArgusBlack y la cargará nuevamente',
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          self.router.ifs_sinc = false
          self.router.bs_sinc = false 
          self.router.pv_sinc = false
          self.router.sc_sinc = false
          RouterSrv.resincronizar(self.router.id, 'todo').then(response => {
            swal.fire(
              "Resincronizar todo",
              "La resincronización está comenzado, manten conectado tu router y no lo desconectes de la energía ni de la conexión a internet",
              "success"
            )
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = ''
            }
            mensaje != '' ? iu.msg.error(mensaje) : ''
            console.log(error)
          })

          self.router.cantidad_cpes.por_sincronizar = self.router.cantidad_cpes.total
          self.router.cantidad_cpes.sincronizados = 0
          RouterSrv.resincronizar(self.router.id, 'configuracion-cpes').catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = ''
            }
            mensaje != '' ? iu.msg.error(mensaje) : ''
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    }
  }
}
</script>

<style scoped>
.form-check-input:checked {
  background-color: #63AD6F;
  border-color: #63AD6F;
}
</style>